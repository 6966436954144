import React, { FC } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import clsx from 'clsx';
import styles from './link.module.css';
import textStyles from './../text/text.module.css';

const regex = /https?:\/\/(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const textVariants = {
  u1a: 'textUtility1a',
  u1b: 'textUtility1b',
  u1bLink: 'textUtility1bLink',
  h2a: 'textH2a',
  p2a: 'textP2a',
  p3a: 'textP3a',
  nav: 'textNav'
};

export interface LinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
  className?: string;
  underline?: boolean;
  underSpace?: boolean;
  color?: 'darkGreen' | 'sage' | 'white' | 'inherit';
  destination: string;
  openInNewTab?: boolean;
  variant?: keyof typeof textVariants;
  ignoreStyles?: boolean;
  activeClassName?: string;
  forceHardNavigation?: boolean;
}

export const Link: FC<LinkProps> = ({
  children,
  className,
  color = 'darkGreen',
  openInNewTab,
  destination,
  underline,
  underSpace,
  variant = 'u1bLink',
  ignoreStyles = false,
  activeClassName = '',
  forceHardNavigation = false,
  ...props
}) => {
  const variantName = textVariants[variant];

  const linkClassNames = clsx(
    styles.link,
    styles[color],
    underline && 'underline',
    underSpace && 'underspace',
    className,
    !ignoreStyles && textStyles[variantName]
  );

  const isExternal =
    destination?.includes('tel:') ||
    regex.test(destination) ||
    destination?.includes('mailto:');

  const targetProps =
    openInNewTab || isExternal
      ? {
          target: '_blank',
          rel: 'noopener noreferrer'
        }
      : {};

  const isProduction = process.env.GATSBY_ACTIVE_ENV === 'production';
  let finalHref = destination;

  if (
    !isProduction &&
    !finalHref?.includes('staging.parsleyhealth.com') &&
    !isExternal
  ) {
    finalHref = finalHref?.replace(
      'parsleyhealth.com',
      'staging.parsleyhealth.com'
    );
  }

  if (forceHardNavigation || isExternal) {
    return (
      <a
        href={finalHref}
        {...targetProps}
        className={!ignoreStyles ? linkClassNames : className}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink
      to={finalHref}
      className={!ignoreStyles ? linkClassNames : className}
      activeClassName={activeClassName}
      {...props}
    >
      {children}
    </GatsbyLink>
  );
};
