import { useStaticQuery, graphql } from 'gatsby';
import { useNewProgram } from '@parsleyhealth/cilantro-ui';

// This map holds the id of the nav as its keys and the name we want
// to give each specific nav in the return value
const navigationIds = new Map();
navigationIds.set('blog-internal-navigation', 'blogInternalNav');
navigationIds.set('blog-category-navigation', 'blogCategoryNav');
navigationIds.set('blog-external-navigation', 'blogExternalNav');
navigationIds.set('63fb593b-ae93-4cba-8a5d-f9ecc53bfa74', 'mainPrimaryNav');
navigationIds.set('70f5148f-2fd2-4629-83d8-0d2c9975fab4', 'mainSecondaryNav');

const navigationSanityTitles = new Map();
navigationSanityTitles.set(
  'hilton-education-header-navigation',
  'hiltonHeaderNav'
);
navigationSanityTitles.set(
  'hilton-education-internal-navigation',
  'hiltonInternalNav'
);
navigationSanityTitles.set(
  'hilton-education-external-navigation',
  'hiltonExternalNav'
);

const useNavigation = () => {
  const { allSanityNavigation } = useStaticQuery(
    graphql`
      query getSanityNavigation {
        allSanityNavigation {
          nodes {
            navigationItem {
              path
              title
              hideInProduction
              childrenItems {
                path
                title
                hideInProduction
                _key
              }
              _key
            }
            sanityTitle
            id: _id
          }
        }
      }
    `
  );

  const {
    mainPrimaryNav,
    blogCategoryNav,
    blogInternalNav,
    blogExternalNav,
    hiltonHeaderNav,
    hiltonInternalNav,
    hiltonExternalNav,
    mainSecondaryNav
  } = allSanityNavigation?.nodes.reduce((acc: any, nav: any) => {
    const navigationName =
      navigationIds.get(nav.id.replace('drafts.', '')) ||
      navigationSanityTitles.get(nav.sanityTitle);
    if (navigationName) {
      acc[navigationName] = nav.navigationItem; // These are the nav items
    }

    return acc;
  }, {});

  const { isAllowed } = useNewProgram(false, '/');

  return {
    mainPrimaryNav: !isAllowed
      ? [
          mainPrimaryNav.find((nav: any) => nav.title === 'What We Treat'),
          { title: 'How We Work', path: '/how-we-work' },
          { title: 'Pricing & Insurance', path: '/pricing-and-insurance' },
          {
            ...mainPrimaryNav.find((nav: any) => nav.title === 'For Employers'),
            title: 'Employers'
          }
        ]
      : mainPrimaryNav,
    blogCategoryNav,
    blogInternalNav,
    blogExternalNav,
    hiltonHeaderNav,
    hiltonInternalNav,
    hiltonExternalNav,
    mainSecondaryNav
  };
};

export default useNavigation;
