import React, { ReactNode } from 'react';
import { Text } from '../../atoms/text/text';
import { Button } from '../../atoms/button/button';
import { SanityScheduleCall } from '../../../../graphql-types';
import './schedule-call.scss';
import { TButtonColor } from '../../types';

interface ScheduleCallProps
  extends Omit<SanityScheduleCall, 'scheduleCallDescription'> {
  description: ReactNode;
  buttonColor?: TButtonColor;
  secondaryButton?: {
    text: string;
    destination: string;
  };
}

export const ScheduleCall = ({
  title,
  description,
  scheduleACallButtonLink,
  buttonColor = 'sage',
  secondaryButton
}: ScheduleCallProps) => {
  return (
    <div className="flex align-center flex-col schedule-call-container">
      <div className="flex align-center flex-col schedule-call-wrapper">
        <Text
          variant="widget"
          className="pl-tiny pr-tiny pb-sm schedule-call-title"
        >
          {title}
        </Text>
        <div className="schedule-call-description text-center">
          {typeof description === 'string' ? (
            <Text variant="p2a" className="pb-sm text-center">
              {description}
            </Text>
          ) : (
            <>{description}</>
          )}
        </div>
        <div className="schedule-call-button-wrapper">
          <Button
            color={buttonColor}
            trackingLabel="scheduleCallButton"
            destination={scheduleACallButtonLink.destination}
          >
            {scheduleACallButtonLink.text}
          </Button>
          {secondaryButton && (
            <Button
              color={buttonColor}
              variant="outlined"
              trackingLabel="scheduleCallSecondaryButton"
              destination={secondaryButton.destination}
            >
              {secondaryButton.text}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
