import React from 'react';
import styles from './footer-legal.module.css';

import { Column } from '../../atoms/grid';
import { Link, Text } from '../..';
import FooterData from '../../content/footer/footer.json';
import { ContentLinkType } from '../../types/index';
import { ColumnUnit } from 'src/redesign/types';

interface FooterLegalProps {
  cols?: ColumnUnit;
}

export const FooterLegal: React.FC<FooterLegalProps> = ({ cols }) => {
  return (
    <Column className={styles.footerLegalLinks} cols={cols}>
      <Text variant="u1a" color="white">
        © {new Date().getFullYear()} Parsley Health
      </Text>
      {FooterData['bottom-bar'].links.map((l: ContentLinkType) => (
        <Link
          forceHardNavigation={true}
          variant="u1a"
          color="white"
          key={l.copy}
          destination={l.destination}
          className={styles.footerLegalLink}
        >
          {l.copy}
        </Link>
      ))}
    </Column>
  );
};
